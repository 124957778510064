import React, { useEffect, useState } from 'react';

import {
    ForeignKey,
    Filter,
    ColumnChooser,
    Group,
    Sort,
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Inject,
    Edit,
    Reorder,
    Resize,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { DialogFormTemplate } from './ImmobilieEditTemplate'; // Ensure correct path to the file
import './ImmobilieStyles.css'; // Ensure to import the CSS file
import { anredeData } from '../../data/luData.js';

const DataGridComponent = () => {
    const immobilieApiUrl = 'http://localhost:3001/api/immobilie'; // Define base URL for Kunden API
    const [immobilie, setImmobilie] = useState([]);

    const FilterOptions = { type: 'Excel' };
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'ColumnChooser'];
    const pageOptions = { pageSizes: ['All', '10', '25', '50', '100', '250']};

    useEffect(() => {
        const immobilieDataManager = new DataManager({
            url: immobilieApiUrl,
            adaptor: new WebApiAdaptor()
        });

        const immobilieQuery = new Query();
        immobilieDataManager.executeQuery(immobilieQuery).then((e) => {
            setImmobilie(e.result);
            console.log('Fetched immobilien Data:', e.result);
        }).catch((err) => {
            console.error('Error fetching kunden data:', err);
        });
    }, []);

    const handleActionComplete = (args) => {
        const commonHeaders = {
            'Content-Type': 'application/json'
        };
        let url = '';
        let options = {};

        if (args.requestType === 'save') {
            if (args.action === 'add') {
                url = immobilieApiUrl;
                options = {
                    method: 'POST',
                    headers: commonHeaders,
                    body: JSON.stringify(args.data)
                };
            } else if (args.action === 'edit') {
                url = `${immobilieApiUrl}/${args.data.id}`;
                options = {
                    method: 'PUT',
                    headers: commonHeaders,
                    body: JSON.stringify(args.data)
                };
            }

            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Record processed:', data);
                })
                .catch(error => {
                    console.error('Error processing record:', error);
                });
        } else if (args.requestType === 'delete') {
            url = `${immobilieApiUrl}/${args.data[0].id}`;
            options = {
                method: 'DELETE',
                headers: commonHeaders
            };

            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Record deleted:', data);
                    // Refresh the grid after deletion
                    return new DataManager({
                        url: immobilieApiUrl,
                        adaptor: new WebApiAdaptor()
                    }).executeQuery(new Query());
                })
                .then((e) => {
                    setImmobilie(e.result); // Refresh the grid with updated data
                })
                .catch(error => {
                    console.error('Error deleting record:', error);
                });
        }
    };
    return (
        <div className="full-height">
            <GridComponent
                height='calc(100vh - 240px)'
                width='100%'
                dataSource={immobilie}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowGrouping={true}
                allowReordering={true}
                allowResizing={true}
                resizeSettings={{ mode: 'Auto' }}
                autoFit={true}
                enablePersistence={true}
                showColumnChooser={true}
                toolbar={toolbarOptions}
                filterSettings={FilterOptions}
                pageSettings={pageOptions}
                editSettings={{
                    allowAdding: true,
                    allowEditing: true,
                    allowDeleting: true,
                    mode: 'Dialog',
                    template: (props) => <DialogFormTemplate {...props} anredeData={anredeData} />
                }}
                actionComplete={handleActionComplete}
            >
                <ColumnsDirective>
                    <ColumnDirective field='id' headerText='ID' isPrimaryKey={true} type='int' width='100' textAlign='Right' visible={false} />
                    <ColumnDirective field='bezeichnung' headerText='Bezeichnung' width='200' />
                    <ColumnDirective field='anschrift' headerText='Anschrift' width='200' />
                    <ColumnDirective field='plz' headerText='PLZ' width='200' />
                    <ColumnDirective field='ort' headerText='Ort' width='200' />

                    <ColumnDirective field='wohnungsnummer' headerText='Wohnungsnummer' width='200' />
                    <ColumnDirective field='groesse' headerText='Größe' width='200' />
                    <ColumnDirective field='kaufpreis' headerText='Kaufpreis' width='200' />
                    <ColumnDirective field='mietpool' headerText='Mietpool' format='dd.MM.yyyy'  width='200' editType="datepickeredit"/>
                    <ColumnDirective field='bezugsfertig' headerText='Bezugsfertig' format='dd.MM.yyyy'  width='200' />
                    <ColumnDirective field='mieteinnahmen' headerText='Mieteinnahmen' width='200' />
                    <ColumnDirective field='baeh' headerText='BAEH' width='200' />
                    <ColumnDirective field='nveh' headerText='NVEH' width='200' />

                    <ColumnDirective field='bemerkung' headerText='Bemerkung' width='200' visible={false}/>
                </ColumnsDirective>
                <Inject services={[Filter, Sort, Group, Page, Edit, Toolbar, Reorder, ColumnChooser, Resize, ForeignKey]} />
            </GridComponent>
        </div>
    );
};

export default DataGridComponent;