import React, { Component } from 'react';
import { RichTextEditorComponent } from "@syncfusion/ej2-react-richtexteditor";
import AnsprechpartnerGrid from "./AnsprechpartnerGrid";

export class DialogFormTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(args) {
        const target = args.target || args.itemData;
        const name = target.name || args.name;
        const value = target.value || args.value;
        this.setState({ [name]: value });
    }

    handleRichTextChange(value) {
        this.setState({ bemerkung: value });
    }

    render() {
        const data = this.state;
        const { anredeData, entityType, entityId } = this.props;

        return (
            <div className="e-gridform full-width-form">
                <div className="form-row">
                    <div className="form-group col-12">
                        <div className="e-float-input e-control-wrapper">
                            <input
                                value={data.notariat}
                                id="notariat"
                                name="notariat"
                                type="text"
                                onChange={this.onChange}
                            />
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Notariat</label>
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input
                                value={data.anschrift}
                                id="anschrift"
                                name="anschrift"
                                type="text"
                                onChange={this.onChange}
                            />
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Anschrift</label>
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input
                                value={data.plz}
                                id="plz"
                                name="plz"
                                type="text"
                                onChange={this.onChange}
                            />
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">PLZ</label>
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input
                                value={data.ort}
                                id="ort"
                                name="ort"
                                type="text"
                                onChange={this.onChange}
                            />
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Ort</label>
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-6">
                        <div className="e-float-input e-control-wrapper">
                            <input
                                value={data.telefon}
                                id="telefon"
                                name="telefon"
                                type="text"
                                onChange={this.onChange}
                            />
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Telefon</label>
                        </div>
                    </div>
                    <div className="form-group col-6">
                        <div className="e-float-input e-control-wrapper">
                            <input
                                value={data.email}
                                id="email"
                                name="email"
                                type="text"
                                onChange={this.onChange}
                            />
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Email</label>
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-12">
                        <label className="e-label-top mb-2">Bemerkung</label>
                        <RichTextEditorComponent
                            id="bemerkung"
                            value={data.bemerkung}
                            change={(e) => this.handleRichTextChange(e.value)}
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-12">
                        <label className="e-label-top mb-2">Ansprechpartner</label>
                        <AnsprechpartnerGrid
                            entityType="business"
                            entityId={data.id}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
