import React, { Component } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RichTextEditorComponent, Toolbar, Link,  Image, HtmlEditor, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
RichTextEditorComponent.Inject(Toolbar, Link, HtmlEditor, Image, QuickToolbar);

export class DialogFormTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        this.onChange = this.onChange.bind(this);
        this.handleRichTextChange = this.handleRichTextChange.bind(this);
    }

    onChange(args) {
        const target = args.target || args.itemData;
        const name = target.name || args.name;
        const value = target.value || args.value;
        this.setState({ [name]: value });
    }

    handleRichTextChange(content) {
        this.setState({ bemerkung: content }); // Update the state with the content of the Rich Text Editor
    }

    render() {
        const data = this.state;
        const { anredeData } = this.props;

        return (

            <div className="e-gridform full-width-form">

                <div className="form-row">
                    <div className="form-group col-md-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.beraternummer} id="beraternummer" name="beraternummer" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Beraternummer</label>
                        </div>
                    </div>
                    <div className="form-group col-md-6"/>
                </div>
                <div className="form-row">
                    <div className="form-group col-4">
                        <DropDownListComponent
                            id="anrede_id"
                            name="anrede_id"
                            value={data.anrede_id}
                            dataSource={anredeData}
                            fields={{text: 'salutation', value: 'anrede_id'}}
                            placeholder="Anrede"
                            popupHeight="300px"
                            floatLabelType="Always"
                            change={this.onChange}
                        />
                    </div>
                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.vorname} id="vorname" name="vorname" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Vorname</label>
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.nachname} id="nachname" name="nachname" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Nachname</label>
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.anschrift} id="anschrift" name="anschrift" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Anschrift</label>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.plz} id="plz" name="plz" type="text" onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">PLZ</label>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.ort} id="ort" name="ort" type="text" onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Ort</label>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.telefon} id="telefon" name="telefon" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Telefon</label>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.email} id="email" name="email" type="text" onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Email</label>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                    <RichTextEditorComponent
                    id="bemerkung"
                    value={data.bemerkung}
                    change={(e) => this.handleRichTextChange(e.value)}
                    />
                    </div>
                </div>
            </div>
        );
    }
}
