export let anredeData = [{
    'anrede_id': 1,
    'salutation': 'Firma'
},
    {
        'anrede_id': 2,
        'salutation': 'Herr'
    },
    {
        'anrede_id': 3,
        'salutation': 'Frau'
    },
    {
        'anrede_id': 4,
        'salutation': 'Divers'
    }];

export let rightsData = [{
    'id': 9,
    'role': 'SuperAdmin',
},
    {
        'id': 8,
        'role': 'Admin'
    },
    {
        'id': 6,
        'role': 'Benutzer'
    },
    {
        'id': 3,
        'role': 'Aushilfe'
    },
    {
        'id': 1,
        'role': 'Gast'
    }];

export let statusData = [{
        'id': 10,
        'status': 'Teilnahme Seminar'
    },
    {
        'id': 20,
        'status': '1. Gespräch'
    },
    {
        'id': 30,
        'status': 'Vorstellung Machbarkeit'
    },
    {
        'id': 40,
        'status': 'Einwertung offen'
    },
    {
        'id': 50,
        'status': 'Einwertung erfolgt'
    },
    {
        'id': 60,
        'status': 'Keine Einwertung möglich'
    },
    {
        'id': 70,
        'status': 'Reservierung'
    },
    {
        'id': 80,
        'status': 'Wohnung zugeteilt'
    },
    {
        'id': 90,
        'status': 'Meldung Notartermin'
    },
    {
        'id': 100,
        'status': 'Finanzierung in Arbeit'
    },
    {
        'id': 110,
        'status': 'Finanzierung zugesagt'
    },
    {
        'id': 120,
        'status': 'Eigenkapitalnachweis vorliegend'
    },
    {
        'id': 130,
        'status': 'Darlehensvertrag vorliegend'
    },
    {
        'id': 140,
        'status': 'Notartermin stattgefunden'
    },
    {
        'id': 150,
        'status': 'Umsatz eingereicht'
    },
    {
        'id': 160,
        'status': 'Umsatz erfasst'
    },
    {
        'id': 170,
        'status': 'Umsatz abgerechnet'
    },
    {
        'id': 180,
        'status': 'Erneute Interessensbekundung'
    }];

export let countryData = [
    { 'id': 1, 'code': 'DE', 'country': 'Deutschland', 'staatsbuergerschaft': 'Deutsch' },
    { 'id': 2, 'code': 'AT', 'country': 'Österreich', 'staatsbuergerschaft': 'Österreichisch' },
    { 'id': 3, 'code': 'CH', 'country': 'Schweiz', 'staatsbuergerschaft': 'Schweizerisch' },
    { 'id': 4, 'code': 'AF', 'country': 'Afghanistan', 'staatsbuergerschaft': 'Afghanisch' },
    { 'id': 5, 'code': 'AL', 'country': 'Albanien', 'staatsbuergerschaft': 'Albanisch' },
    { 'id': 6, 'code': 'DZ', 'country': 'Algerien', 'staatsbuergerschaft': 'Algerisch' },
    { 'id': 7, 'code': 'AD', 'country': 'Andorra', 'staatsbuergerschaft': 'Andorranisch' },
    { 'id': 8, 'code': 'AO', 'country': 'Angola', 'staatsbuergerschaft': 'Angolanisch' },
    { 'id': 9, 'code': 'AG', 'country': 'Antigua und Barbuda', 'staatsbuergerschaft': 'Antiguanisch' },
    { 'id': 10, 'code': 'AR', 'country': 'Argentinien', 'staatsbuergerschaft': 'Argentinisch' },
    { 'id': 11, 'code': 'AM', 'country': 'Armenien', 'staatsbuergerschaft': 'Armenisch' },
    { 'id': 12, 'code': 'AU', 'country': 'Australien', 'staatsbuergerschaft': 'Australisch' },
    { 'id': 13, 'code': 'AZ', 'country': 'Aserbaidschan', 'staatsbuergerschaft': 'Aserbaidschanisch' },
    { 'id': 14, 'code': 'BS', 'country': 'Bahamas', 'staatsbuergerschaft': 'Bahamesisch' },
    { 'id': 15, 'code': 'BH', 'country': 'Bahrain', 'staatsbuergerschaft': 'Bahrainisch' },
    { 'id': 16, 'code': 'BD', 'country': 'Bangladesch', 'staatsbuergerschaft': 'Bangladeschisch' },
    { 'id': 17, 'code': 'BB', 'country': 'Barbados', 'staatsbuergerschaft': 'Barbadisch' },
    { 'id': 18, 'code': 'BE', 'country': 'Belgien', 'staatsbuergerschaft': 'Belgisch' },
    { 'id': 19, 'code': 'BZ', 'country': 'Belize', 'staatsbuergerschaft': 'Belizisch' },
    { 'id': 20, 'code': 'BJ', 'country': 'Benin', 'staatsbuergerschaft': 'Beninisch' },
    { 'id': 21, 'code': 'BT', 'country': 'Bhutan', 'staatsbuergerschaft': 'Bhutanisch' },
    { 'id': 22, 'code': 'BO', 'country': 'Bolivien', 'staatsbuergerschaft': 'Bolivianisch' },
    { 'id': 23, 'code': 'BA', 'country': 'Bosnien und Herzegowina', 'staatsbuergerschaft': 'Bosnisch-Herzegowinisch' },
    { 'id': 24, 'code': 'BW', 'country': 'Botsuana', 'staatsbuergerschaft': 'Botsuanisch' },
    { 'id': 25, 'code': 'BR', 'country': 'Brasilien', 'staatsbuergerschaft': 'Brasilianisch' },
    { 'id': 26, 'code': 'BN', 'country': 'Brunei', 'staatsbuergerschaft': 'Bruneiisch' },
    { 'id': 27, 'code': 'BG', 'country': 'Bulgarien', 'staatsbuergerschaft': 'Bulgarisch' },
    { 'id': 28, 'code': 'BF', 'country': 'Burkina Faso', 'staatsbuergerschaft': 'Burkinisch' },
    { 'id': 29, 'code': 'BI', 'country': 'Burundi', 'staatsbuergerschaft': 'Burundisch' },
    { 'id': 30, 'code': 'CV', 'country': 'Kap Verde', 'staatsbuergerschaft': 'Kapverdisch' },
    { 'id': 31, 'code': 'KH', 'country': 'Kambodscha', 'staatsbuergerschaft': 'Kambodschanisch' },
    { 'id': 32, 'code': 'CM', 'country': 'Kamerun', 'staatsbuergerschaft': 'Kamerunisch' },
    { 'id': 33, 'code': 'CA', 'country': 'Kanada', 'staatsbuergerschaft': 'Kanadisch' },
    { 'id': 34, 'code': 'QA', 'country': 'Katar', 'staatsbuergerschaft': 'Katarisch' },
    { 'id': 35, 'code': 'KZ', 'country': 'Kasachstan', 'staatsbuergerschaft': 'Kasachisch' },
    { 'id': 36, 'code': 'KE', 'country': 'Kenia', 'staatsbuergerschaft': 'Kenianisch' },
    { 'id': 37, 'code': 'KG', 'country': 'Kirgisistan', 'staatsbuergerschaft': 'Kirgisisch' },
    { 'id': 38, 'code': 'KI', 'country': 'Kiribati', 'staatsbuergerschaft': 'Kiribatisch' },
    { 'id': 39, 'code': 'CO', 'country': 'Kolumbien', 'staatsbuergerschaft': 'Kolumbianisch' },
    { 'id': 40, 'code': 'KM', 'country': 'Komoren', 'staatsbuergerschaft': 'Komorisch' },
    { 'id': 41, 'code': 'CG', 'country': 'Kongo', 'staatsbuergerschaft': 'Kongolesisch' },
    { 'id': 42, 'code': 'CD', 'country': 'Demokratische Republik Kongo', 'staatsbuergerschaft': 'Kongolesisch' },
    { 'id': 43, 'code': 'CR', 'country': 'Costa Rica', 'staatsbuergerschaft': 'Costa-Ricanisch' },
    { 'id': 44, 'code': 'CI', 'country': 'Elfenbeinküste', 'staatsbuergerschaft': 'Ivorisch' },
    { 'id': 45, 'code': 'HR', 'country': 'Kroatien', 'staatsbuergerschaft': 'Kroatisch' },
    { 'id': 46, 'code': 'CU', 'country': 'Kuba', 'staatsbuergerschaft': 'Kubanisch' },
    { 'id': 47, 'code': 'KW', 'country': 'Kuwait', 'staatsbuergerschaft': 'Kuwaitisch' },
    { 'id': 48, 'code': 'LA', 'country': 'Laos', 'staatsbuergerschaft': 'Laotisch' },
    { 'id': 49, 'code': 'LS', 'country': 'Lesotho', 'staatsbuergerschaft': 'Lesothisch' },
    { 'id': 50, 'code': 'LV', 'country': 'Lettland', 'staatsbuergerschaft': 'Lettisch' },
    { 'id': 51, 'code': 'LR', 'country': 'Liberia', 'staatsbuergerschaft': 'Liberianisch' },
    { 'id': 52, 'code': 'LY', 'country': 'Libyen', 'staatsbuergerschaft': 'Libysch' },
    { 'id': 53, 'code': 'LI', 'country': 'Liechtenstein', 'staatsbuergerschaft': 'Liechtensteinisch' },
    { 'id': 54, 'code': 'LT', 'country': 'Litauen', 'staatsbuergerschaft': 'Litauisch' },
    { 'id': 55, 'code': 'LU', 'country': 'Luxemburg', 'staatsbuergerschaft': 'Luxemburgisch' },
    { 'id': 56, 'code': 'MG', 'country': 'Madagaskar', 'staatsbuergerschaft': 'Madagassisch' },
    { 'id': 57, 'code': 'MW', 'country': 'Malawi', 'staatsbuergerschaft': 'Malawisch' },
    { 'id': 58, 'code': 'MY', 'country': 'Malaysia', 'staatsbuergerschaft': 'Malaysisch' },
    { 'id': 59, 'code': 'MV', 'country': 'Malediven', 'staatsbuergerschaft': 'Maledivisch' },
    { 'id': 60, 'code': 'ML', 'country': 'Mali', 'staatsbuergerschaft': 'Malisch' },
    { 'id': 61, 'code': 'MT', 'country': 'Malta', 'staatsbuergerschaft': 'Maltesisch' },
    { 'id': 62, 'code': 'MA', 'country': 'Marokko', 'staatsbuergerschaft': 'Marokkanisch' },
    { 'id': 63, 'code': 'MH', 'country': 'Marshallinseln', 'staatsbuergerschaft': 'Marshallisch' },
    { 'id': 64, 'code': 'MR', 'country': 'Mauretanien', 'staatsbuergerschaft': 'Mauretanisch' },
    { 'id': 65, 'code': 'MU', 'country': 'Mauritius', 'staatsbuergerschaft': 'Mauritianisch' },
    { 'id': 66, 'code': 'MX', 'country': 'Mexiko', 'staatsbuergerschaft': 'Mexikanisch' },
    { 'id': 67, 'code': 'FM', 'country': 'Mikronesien', 'staatsbuergerschaft': 'Mikronesisch' },
    { 'id': 68, 'code': 'MD', 'country': 'Moldau', 'staatsbuergerschaft': 'Moldauisch' },
    { 'id': 69, 'code': 'MC', 'country': 'Monaco', 'staatsbuergerschaft': 'Monegassisch' },
    { 'id': 70, 'code': 'MN', 'country': 'Mongolei', 'staatsbuergerschaft': 'Mongolisch' },
    { 'id': 71, 'code': 'ME', 'country': 'Montenegro', 'staatsbuergerschaft': 'Montenegrinisch' },
    { 'id': 72, 'code': 'MZ', 'country': 'Mosambik', 'staatsbuergerschaft': 'Mosambikanisch' },
    { 'id': 73, 'code': 'MM', 'country': 'Myanmar', 'staatsbuergerschaft': 'Myanmaresisch' },
    { 'id': 74, 'code': 'NA', 'country': 'Namibia', 'staatsbuergerschaft': 'Namibisch' },
    { 'id': 75, 'code': 'NR', 'country': 'Nauru', 'staatsbuergerschaft': 'Nauruisch' },
    { 'id': 76, 'code': 'NP', 'country': 'Nepal', 'staatsbuergerschaft': 'Nepalesisch' },
    { 'id': 77, 'code': 'NL', 'country': 'Niederlande', 'staatsbuergerschaft': 'Niederländisch' },
    { 'id': 78, 'code': 'NZ', 'country': 'Neuseeland', 'staatsbuergerschaft': 'Neuseeländisch' },
    { 'id': 79, 'code': 'NI', 'country': 'Nicaragua', 'staatsbuergerschaft': 'Nicaraguanisch' },
    { 'id': 80, 'code': 'NE', 'country': 'Niger', 'staatsbuergerschaft': 'Nigrisch' },
    { 'id': 81, 'code': 'NG', 'country': 'Nigeria', 'staatsbuergerschaft': 'Nigerianisch' },
    { 'id': 82, 'code': 'MK', 'country': 'Nordmazedonien', 'staatsbuergerschaft': 'Nordmazedonisch' },
    { 'id': 83, 'code': 'NO', 'country': 'Norwegen', 'staatsbuergerschaft': 'Norwegisch' },
    { 'id': 84, 'code': 'OM', 'country': 'Oman', 'staatsbuergerschaft': 'Omanisch' },
    { 'id': 85, 'code': 'PK', 'country': 'Pakistan', 'staatsbuergerschaft': 'Pakistanisch' },
    { 'id': 86, 'code': 'PW', 'country': 'Palau', 'staatsbuergerschaft': 'Palauisch' },
    { 'id': 87, 'code': 'PA', 'country': 'Panama', 'staatsbuergerschaft': 'Panamaisch' },
    { 'id': 88, 'code': 'PG', 'country': 'Papua-Neuguinea', 'staatsbuergerschaft': 'Papua-Neuguineisch' },
    { 'id': 89, 'code': 'PY', 'country': 'Paraguay', 'staatsbuergerschaft': 'Paraguayisch' },
    { 'id': 90, 'code': 'PE', 'country': 'Peru', 'staatsbuergerschaft': 'Peruanisch' },
    { 'id': 91, 'code': 'PH', 'country': 'Philippinen', 'staatsbuergerschaft': 'Philippinisch' },
    { 'id': 92, 'code': 'PL', 'country': 'Polen', 'staatsbuergerschaft': 'Polnisch' },
    { 'id': 93, 'code': 'PT', 'country': 'Portugal', 'staatsbuergerschaft': 'Portugiesisch' },
    { 'id': 94, 'code': 'QA', 'country': 'Katar', 'staatsbuergerschaft': 'Katarisch' },
    { 'id': 95, 'code': 'RO', 'country': 'Rumänien', 'staatsbuergerschaft': 'Rumänisch' },
    { 'id': 96, 'code': 'RU', 'country': 'Russland', 'staatsbuergerschaft': 'Russisch' },
    { 'id': 97, 'code': 'RW', 'country': 'Ruanda', 'staatsbuergerschaft': 'Ruandisch' },
    { 'id': 98, 'code': 'LC', 'country': 'St. Lucia', 'staatsbuergerschaft': 'St. Lucianisch' },
    { 'id': 99, 'code': 'VC', 'country': 'St. Vincent und die Grenadinen', 'staatsbuergerschaft': 'Vincentisch' },
    { 'id': 100, 'code': 'WS', 'country': 'Samoa', 'staatsbuergerschaft': 'Samoanisch' },
    { 'id': 101, 'code': 'SM', 'country': 'San Marino', 'staatsbuergerschaft': 'San-Marinesisch' },
    { 'id': 102, 'code': 'ST', 'country': 'São Tomé und Príncipe', 'staatsbuergerschaft': 'São-Toméisch' },
    { 'id': 103, 'code': 'SA', 'country': 'Saudi-Arabien', 'staatsbuergerschaft': 'Saudi-Arabisch' },
    { 'id': 104, 'code': 'SN', 'country': 'Senegal', 'staatsbuergerschaft': 'Senegalesisch' },
    { 'id': 105, 'code': 'RS', 'country': 'Serbien', 'staatsbuergerschaft': 'Serbisch' },
    { 'id': 106, 'code': 'SC', 'country': 'Seychellen', 'staatsbuergerschaft': 'Seychellisch' },
    { 'id': 107, 'code': 'SL', 'country': 'Sierra Leone', 'staatsbuergerschaft': 'Sierra-Leonisch' },
    { 'id': 108, 'code': 'SG', 'country': 'Singapur', 'staatsbuergerschaft': 'Singapurisch' },
    { 'id': 109, 'code': 'SK', 'country': 'Slowakei', 'staatsbuergerschaft': 'Slowakisch' },
    { 'id': 110, 'code': 'SI', 'country': 'Slowenien', 'staatsbuergerschaft': 'Slowenisch' },
    { 'id': 111, 'code': 'SO', 'country': 'Somalia', 'staatsbuergerschaft': 'Somalisch' },
    { 'id': 112, 'code': 'ES', 'country': 'Spanien', 'staatsbuergerschaft': 'Spanisch' },
    { 'id': 113, 'code': 'LK', 'country': 'Sri Lanka', 'staatsbuergerschaft': 'Sri-Lankisch' },
    { 'id': 114, 'code': 'SD', 'country': 'Sudan', 'staatsbuergerschaft': 'Sudanesisch' },
    { 'id': 115, 'code': 'SR', 'country': 'Suriname', 'staatsbuergerschaft': 'Surinamisch' },
    { 'id': 116, 'code': 'SE', 'country': 'Schweden', 'staatsbuergerschaft': 'Schwedisch' },
    { 'id': 117, 'code': 'SY', 'country': 'Syrien', 'staatsbuergerschaft': 'Syrisch' },
    { 'id': 118, 'code': 'TJ', 'country': 'Tadschikistan', 'staatsbuergerschaft': 'Tadschikisch' },
    { 'id': 119, 'code': 'TZ', 'country': 'Tansania', 'staatsbuergerschaft': 'Tansanisch' },
    { 'id': 120, 'code': 'TH', 'country': 'Thailand', 'staatsbuergerschaft': 'Thailändisch' },
    { 'id': 121, 'code': 'TL', 'country': 'Timor-Leste', 'staatsbuergerschaft': 'Timoresisch' },
    { 'id': 122, 'code': 'TG', 'country': 'Togo', 'staatsbuergerschaft': 'Togolesisch' },
    { 'id': 123, 'code': 'TO', 'country': 'Tonga', 'staatsbuergerschaft': 'Tongaisch' },
    { 'id': 124, 'code': 'TT', 'country': 'Trinidad und Tobago', 'staatsbuergerschaft': 'Trinidadisch' },
    { 'id': 125, 'code': 'TN', 'country': 'Tunesien', 'staatsbuergerschaft': 'Tunesisch' },
    { 'id': 126, 'code': 'TR', 'country': 'Türkei', 'staatsbuergerschaft': 'Türkisch' },
    { 'id': 127, 'code': 'TM', 'country': 'Turkmenistan', 'staatsbuergerschaft': 'Turkmenisch' },
    { 'id': 128, 'code': 'TV', 'country': 'Tuvalu', 'staatsbuergerschaft': 'Tuvaluisch' },
    { 'id': 129, 'code': 'UG', 'country': 'Uganda', 'staatsbuergerschaft': 'Ugandisch' },
    { 'id': 130, 'code': 'UA', 'country': 'Ukraine', 'staatsbuergerschaft': 'Ukrainisch' },
    { 'id': 131, 'code': 'AE', 'country': 'Vereinigte Arabische Emirate', 'staatsbuergerschaft': 'Emiratisch' },
    { 'id': 132, 'code': 'GB', 'country': 'Vereinigtes Königreich', 'staatsbuergerschaft': 'Britisch' },
    { 'id': 133, 'code': 'US', 'country': 'Vereinigte Staaten', 'staatsbuergerschaft': 'Amerikanisch' },
    { 'id': 134, 'code': 'UY', 'country': 'Uruguay', 'staatsbuergerschaft': 'Uruguayisch' },
    { 'id': 135, 'code': 'UZ', 'country': 'Usbekistan', 'staatsbuergerschaft': 'Usbekisch' },
    { 'id': 136, 'code': 'VU', 'country': 'Vanuatu', 'staatsbuergerschaft': 'Vanuatuisch' },
    { 'id': 137, 'code': 'VA', 'country': 'Vatikanstadt', 'staatsbuergerschaft': 'Vatikanisch' },
    { 'id': 138, 'code': 'VE', 'country': 'Venezuela', 'staatsbuergerschaft': 'Venezolanisch' },
    { 'id': 139, 'code': 'VN', 'country': 'Vietnam', 'staatsbuergerschaft': 'Vietnamesisch' },
    { 'id': 140, 'code': 'YE', 'country': 'Jemen', 'staatsbuergerschaft': 'Jemenitisch' },
    { 'id': 141, 'code': 'ZM', 'country': 'Sambia', 'staatsbuergerschaft': 'Sambisch' },
    { 'id': 142, 'code': 'ZW', 'country': 'Simbabwe', 'staatsbuergerschaft': 'Simbabwisch' }
];
