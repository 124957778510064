import React, { useEffect, useState } from 'react';
import {
    ForeignKey,
    Filter,
    ColumnChooser,
    Group,
    Sort,
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Inject,
    Edit,
    Reorder,
    Resize,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { DialogFormTemplate } from './KundenEditTemplate'; // Ensure correct path to the file
import './KundenStyles.css'; // Ensure to import the CSS file
import { anredeData, statusData, countryData } from '../../data/luData.js';

const DataGridComponent = () => {
    const kundenApiUrl = 'http://localhost:3001/api/kunden'; // Define base URL for Kunden API
    const [kunden, setKunden] = useState([]);

    const FilterOptions = { type: 'Excel' };
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'ColumnChooser'];
    const pageOptions = {  pageSize: 8, pageSizes: true};

    useEffect(() => {
        const kundenDataManager = new DataManager({
            url: kundenApiUrl,
            adaptor: new WebApiAdaptor()
        });

        const kundenQuery = new Query();
        kundenDataManager.executeQuery(kundenQuery).then((e) => {
            setKunden(e.result);
            console.log('Fetched Kunden Data:', e.result);
        }).catch((err) => {
            console.error('Error fetching kunden data:', err);
        });
    }, []);

    const handleActionComplete = (args) => {
        const commonHeaders = {
            'Content-Type': 'application/json'
        };
        let url = '';
        let options = {};

        if (args.requestType === 'save') {
            if (args.action === 'add') {
                url = kundenApiUrl;
                options = {
                    method: 'POST',
                    headers: commonHeaders,
                    body: JSON.stringify(args.data)
                };
            } else if (args.action === 'edit') {
                url = `${kundenApiUrl}/${args.data.id}`;
                options = {
                    method: 'PUT',
                    headers: commonHeaders,
                    body: JSON.stringify(args.data)
                };
            }

            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Record processed:', data);
                    // Re-fetch data to update the grid
                    return new DataManager({
                        url: kundenApiUrl,
                        adaptor: new WebApiAdaptor()
                    }).executeQuery(new Query());
                })
                .then((e) => {
                    setKunden(e.result); // Refresh the grid with updated data
                })
                .catch(error => {
                    console.error('Error processing record:', error);
                });
        } else if (args.requestType === 'delete') {
            url = `${kundenApiUrl}/${args.data[0].id}`;
            options = {
                method: 'DELETE',
                headers: commonHeaders
            };

            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Record deleted:', data);
                    // Refresh the grid after deletion
                    return new DataManager({
                        url: kundenApiUrl,
                        adaptor: new WebApiAdaptor()
                    }).executeQuery(new Query());
                })
                .then((e) => {
                    setKunden(e.result); // Refresh the grid with updated data
                })
                .catch(error => {
                    console.error('Error deleting record:', error);
                });
        }
    };
    return (
        <div className="full-height">
            <GridComponent
                height='calc(100vh - 240px)'
                width='100%'
                dataSource={kunden}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowGrouping={true}
                allowReordering={true}
                allowResizing={true}
                resizeSettings={{ mode: 'Auto' }}
                autoFit={true}
                enablePersistence={true}
                showColumnChooser={true}
                toolbar={toolbarOptions}
                filterSettings={FilterOptions}
                pageSettings={pageOptions}
                editSettings={{
                    allowAdding: true,
                    allowEditing: true,
                    allowDeleting: true,
                    mode: 'Dialog',
                    template: (props) => <DialogFormTemplate {...props} anredeData={anredeData} statusData={statusData} countryData={countryData}/>
                }}
                actionComplete={handleActionComplete}
            >
                <ColumnsDirective>
                    <ColumnDirective field='id' headerText='ID' isPrimaryKey={true} type='int' width='100' textAlign='Right' visible={false} />
                    <ColumnDirective field='kundennummer' headerText='Kd.-Nr' width='200' />

                    {/*<ColumnDirective*/}
                    {/*    field='status_id'*/}
                    {/*    headerText='Status'*/}
                    {/*    width='200'*/}
                    {/*    foreignKeyValue='status' // The field to display in the grid from the anrede data*/}
                    {/*    foreignKeyField='id' // The field in the anrede data that matches the kunden table*/}
                    {/*    dataSource={statusData} // The anrede data fetched from the API*/}
                    {/*/>*/}

                    {/*<ColumnDirective*/}
                    {/*    field='anrede_id'*/}
                    {/*    headerText='Anrede'*/}
                    {/*    width='200'*/}
                    {/*    foreignKeyValue='salutation' // The field to display in the grid from the anrede data*/}
                    {/*    foreignKeyField='anrede_id' // The field in the anrede data that matches the kunden table*/}
                    {/*    dataSource={anredeData} // The anrede data fetched from the API*/}
                    {/*/>*/}

                    <ColumnDirective field='vorname' headerText='Vorname' width='200' />
                    <ColumnDirective field='nachname' headerText='Nachname' width='200' />
                    <ColumnDirective field='anschrift' headerText='Anschrift' width='200' />
                    <ColumnDirective field='plz' headerText='PLZ' width='200' />
                    <ColumnDirective field='ort' headerText='Ort' width='200' />

                    {/*<ColumnDirective*/}
                    {/*    field='land_id'*/}
                    {/*    headerText='Land'*/}
                    {/*    width='200'*/}
                    {/*    foreignKeyValue='country'*/}
                    {/*    foreignKeyField='id'*/}
                    {/*    dataSource={countryData}*/}
                    {/*    visible={false}*/}
                    {/*/>*/}

                    {/*<ColumnDirective*/}
                    {/*    field='staatsbuergerschaft_id'*/}
                    {/*    headerText='Staatsbürgerschaft'*/}
                    {/*    width='200'*/}
                    {/*    foreignKeyValue='staatsbuergerschaft'*/}
                    {/*    foreignKeyField='id'*/}
                    {/*    dataSource={countryData}*/}
                    {/*    visible={false}*/}
                    {/*/>*/}
                    <ColumnDirective field='telefon' headerText='Telefon' width='200' />
                    <ColumnDirective field='email' headerText='E-Mail' width='200' />
                    <ColumnDirective field='bemerkung' headerText='Bemerkung' width='200' visible={false}/>
                </ColumnsDirective>
                <Inject services={[Filter, Sort, Group, Page, Edit, Toolbar, Reorder, ColumnChooser, Resize, ForeignKey]} />
            </GridComponent>
        </div>
    );
};

export default DataGridComponent;