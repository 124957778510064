import React, { useEffect, useState } from 'react';
import {
    ForeignKey,
    Filter,
    Sort,
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Inject,
    Edit,
    Reorder,
    Resize,
} from '@syncfusion/ej2-react-grids';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { anredeData } from '../../data/luData.js';
import './NotarStyles.css';

const AnsprechpartnerGrid = ({ entityType, entityId }) => {
    const ansprechpartnerApiUrl = 'http://localhost:3001/api/ansprechpartner';
    const [ansprechpartner, setAnsprechpartner] = useState([]);

    const FilterOptions = { type: 'Excel' };
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];

    useEffect(() => {
        const ansprechpartnerDataManager = new DataManager({
            url: ansprechpartnerApiUrl,
            adaptor: new WebApiAdaptor()
        });

        const beraterQuery = new Query();
        ansprechpartnerDataManager.executeQuery(beraterQuery).then((e) => {
            setAnsprechpartner(e.result);
            console.log('Fetched Ansprechpartner Data:', e.result);
        }).catch((err) => {
            console.error('Error fetching data:', err);
        });
    }, []);

    const handleActionComplete = (args) => {
        const commonHeaders = {
            'Content-Type': 'application/json'
        };
        let url = '';
        let options = {};

        if (args.requestType === 'save') {
            // Automatically add related_entity_type and related_entity_id
            args.data.related_entity_type = entityType;
            args.data.related_entity_id = entityId;

            if (args.action === 'add') {
                url = ansprechpartnerApiUrl;
                options = {
                    method: 'POST',
                    headers: commonHeaders,
                    body: JSON.stringify(args.data)
                };
            } else if (args.action === 'edit') {
                url = `${ansprechpartnerApiUrl}/${args.data.id}`;
                options = {
                    method: 'PUT',
                    headers: commonHeaders,
                    body: JSON.stringify(args.data)
                };
            }

            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Record processed:', data);
                    // Refresh the grid data after saving
                    return new DataManager({
                        url: ansprechpartnerApiUrl,
                        adaptor: new WebApiAdaptor()
                    }).executeQuery(new Query());
                })
                .then((e) => {
                    setAnsprechpartner(e.result);
                })
                .catch(error => {
                    console.error('Error processing record:', error);
                });
        } else if (args.requestType === 'delete') {
            url = `${ansprechpartnerApiUrl}/${args.data[0].id}`;
            options = {
                method: 'DELETE',
                headers: commonHeaders
            };

            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Record deleted:', data);
                    return new DataManager({
                        url: ansprechpartnerApiUrl,
                        adaptor: new WebApiAdaptor()
                    }).executeQuery(new Query());
                })
                .then((e) => {
                    setAnsprechpartner(e.result);
                })
                .catch(error => {
                    console.error('Error deleting record:', error);
                });
        }
    };

    return (
        <div className="full-height">
            <GridComponent
                height='100%'
                width='100%'
                dataSource={ansprechpartner}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowGrouping={false}
                allowResizing={true}
                resizeSettings={{ mode: 'Auto' }}
                autoFit={true}
                enablePersistence={true}
                toolbar={toolbarOptions}
                filterSettings={FilterOptions}
                editSettings={{
                    allowAdding: true,
                    allowEditing: true,
                    allowDeleting: true,
                    mode: 'Dialog'
                }}
                actionComplete={handleActionComplete}
            >
                <ColumnsDirective>
                    <ColumnDirective field='id' headerText='ID' isPrimaryKey={true} type='int' width='100' textAlign='Right' visible={false} />
                    <ColumnDirective field='related_type' headerText='Entity Type' width='200' visible={false} />
                    <ColumnDirective field='related_id' headerText='Entity ID' width='200' visible={false} />

                    <ColumnDirective
                        field='anrede_id'
                        headerText='Anrede'
                        width='200'
                        foreignKeyValue='salutation'
                        foreignKeyField='anrede_id'
                        dataSource={anredeData}
                    />
                    <ColumnDirective field='vorname' headerText='Vorname' width='200' />
                    <ColumnDirective field='nachname' headerText='Nachname' width='200' />
                    <ColumnDirective field='telefon' headerText='Telefon' width='200' />
                    <ColumnDirective field='email' headerText='E-Mail' width='200' />
                    <ColumnDirective field='related_entity_type' headerText='Entity Type' width='200' visible={false} />
                    <ColumnDirective field='related_entity_id' headerText='Entity ID' width='200' visible={false} />
                </ColumnsDirective>
                <Inject services={[Filter, Sort, Page, Edit, Reorder, Resize, ForeignKey]} />
            </GridComponent>
        </div>
    );
};

export default AnsprechpartnerGrid;