
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import * as React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import StartPage from './components/home/StartPage';
import DataGridKunden from './components/kunden/KundenGrid';
import DataGridBerater from './components/berater/BeraterGrid';
import DataGridBenutzer from './components/benutzer/BenutzerGrid';
import DataGridImmobilie from './components/immobilie/ImmobilieGrid';
import DataGridNotar from './components/notar/NotarGrid';
// import DataGridStart from './components/home/StartGrid';
// import DataGridAnsprechpartner from './components/notar/AnsprechpartnerGrid';
import BoldReportViewerComponent from './components/report/Report';

import Sidebar from './components/Sidebar'; // Import the Sidebar component

function Application() {
  const [title, setTitle] = useState('Navigation Pane');
  const sidebarRef = React.useRef(null);

  function toggleClick() {
    if (sidebarRef.current) {
      sidebarRef.current.toggleSidebar();
    }
  }

  return (
      <Router>
        <div id="wrapper">
          <title>Essential JS 2 for React - Sidebar {'>'} Sidebar with ListView</title>
          <div className="control-section">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <div className='main-header' id='header-section'>
                <ul className='header-list'>
                  <li className='float-left header-style icon-menu' id='hamburger' onClick={toggleClick}>
                    ☰
                  </li>
                  <li className='float-left header-style nav-pane'>{title}</li>
                  {/*<li className='header-style float-right support border-left'><b>Support</b></li>*/}
                </ul>
              </div>
              <Sidebar ref={sidebarRef} setTitle={setTitle} />
              <div className="main-content" id="main-text">
                <div className='sidebar-content'>
                  <Routes>
                    <Route path="/benutzer" element={<DataGridBenutzer />} />
                    <Route path="/berater" element={<DataGridBerater />} />
                    <Route path="/kunden" element={<DataGridKunden />} />
                    <Route path="/immobilie" element={<DataGridImmobilie/>} />
                    <Route path="/notar" element={<DataGridNotar/>} />
                    <Route path="/home" element={<BoldReportViewerComponent/>} />
                    {/*<Route path="/home" element={<DataGridStart/>} />*/}
                    <Route path="/" element={<StartPage/>} />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
  );
}

export default Application;
