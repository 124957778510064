import React, { Component } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {RichTextEditorComponent} from "@syncfusion/ej2-react-richtexteditor";
//import ImmobilienTab from './ImmobilieEditTabs';

export class DialogFormTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isHelpModalOpen: false, // State to manage the Help modal
        };
        this.onChange = this.onChange.bind(this);
        this.handleOpenHelpModal = this.handleOpenHelpModal.bind(this);
        this.handleCloseHelpModal = this.handleCloseHelpModal.bind(this);
    }

    handleOpenHelpModal(event) {
        event.preventDefault(); // Prevent default action
        event.stopPropagation(); // Prevent bubbling
        this.setState({ isHelpModalOpen: true });
    }

    handleCloseHelpModal(event) {
        event.preventDefault(); // Prevent default action
        event.stopPropagation(); // Prevent bubbling
        this.setState({ isHelpModalOpen: false });
    }

    onChange(args) {
        const target = args.target || args.itemData;
        const name = target.name || args.name;
        const value = target.value || args.value;
        this.setState({ [name]: value });
    }

    handleRichTextChange(content) {
        this.setState({ bemerkung: content }); // Update the state with the content of the Rich Text Editor
    }

    render() {
        const data = this.state;
        // const { isHelpModalOpen } = this.state;

        // Define the content for the first tab
        const bemerkungContent = (
            <div className="form-group col-12">
                <div className="e-float-input e-control-wrapper">
                    <textarea
                        value={data.bemerkung}
                        id="bemerkung"
                        name="bemerkung"
                        onChange={this.onChange}
                        placeholder="Bemerkung"
                        rows="4"
                    />
                </div>
            </div>
        );

        return (
            <div className="e-gridform">
                <div className="form-row">
                    <div className="form-group col-12">
                        <ButtonComponent onClick={this.handleOpenHelpModal}>Help</ButtonComponent>
                    </div>
                </div>

                <div className="form-row">
                    {/* Existing form elements in 2/3 of the row */}
                    <div className="col-8">
                        <div className="form-row">
                            <div className="form-group col-12">
                                <div className="e-float-input e-control-wrapper">
                                    <TextBoxComponent
                                        value={data.bezeichnung}
                                        id="bezeichnung"
                                        name="bezeichnung"
                                        type="text"
                                        placeholder="Last Name"
                                        floatLabelType="Always"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-4">
                                <div className="e-float-input e-control-wrapper">
                                    <TextBoxComponent
                                        value={data.anschrift}
                                        id="anschrift"
                                        name="anschrift"
                                        type="text"
                                        placeholder="Anschrift"
                                        floatLabelType="Always"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-4">
                                <div className="e-float-input e-control-wrapper">
                                    <TextBoxComponent
                                        value={data.plz}
                                        id="plz"
                                        name="plz"
                                        type="text"
                                        placeholder="PLZ"
                                        floatLabelType="Always"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-4">
                                <div className="e-float-input e-control-wrapper">
                                    <TextBoxComponent
                                        value={data.ort}
                                        id="ort"
                                        name="ort"
                                        type="text"
                                        placeholder="Ort"
                                        floatLabelType="Always"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>

                            <div className="form-group col-md-12">
                                <label className="e-label-top mb-2">Bemerkung</label>
                                <RichTextEditorComponent
                                    id="bemerkung"
                                    value={data.bemerkung}
                                    change={(e) => this.handleRichTextChange(e.value)}
                                />
                            </div>



                        {/* Move Bemerkung field to the tab */}
                        <div className="form-row">
                            <div className="form-group col-12">
                                <div className="e-float-input e-control-wrapper">
                                    <bemerkungContent/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Additional column with 5 input fields in 1/3 of the row */}
                    <div className="col-4" style={{backgroundColor: '#333'}}>
                        <div className="form-group col-12 mt-2">
                            <div className="e-float-input e-control-wrapper">
                                <TextBoxComponent
                                    value={data.wohnungsnummer}
                                    id="wohnungsnummer"
                                    name="wohnungsnummer"
                                    type="text"
                                    placeholder="Wohnungsnummer"
                                    floatLabelType="Always"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>

                        <div className="form-group col-12">
                            <div className="e-float-input e-control-wrapper">
                                <TextBoxComponent
                                    value={data.groesse}
                                    id="groesse"
                                    name="groesse"
                                    type="text"
                                    placeholder="Größe"
                                    floatLabelType="Always"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>

                        <div className="form-group col-12">
                            <div className="e-float-input e-control-wrapper">
                                <TextBoxComponent
                                    value={data.kaufpreis}
                                    id="kaufpreis"
                                    name="kaufpreis"
                                    type="int"
                                    placeholder="Kaufpreis"
                                    floatLabelType="Always"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>

                        <div className="form-group col-12">
                            <DatePickerComponent
                                id="mietpool"
                                name="mietpool"
                                value={data.mietpool}
                                format="dd.MM.yyyy"
                                onChange={this.onChange}
                                placeholder="Mietpool"
                                floatLabelType="Always"
                            />
                        </div>
                        <div className="form-group col-12">
                            <DatePickerComponent
                                id="bezugsfertig"
                                name="bezugsfertig"
                                value={data.bezugsfertig}
                                format="dd.MM.yyyy"
                                onChange={this.onChange}
                                placeholder="Bezugsfertig ab"
                                floatLabelType="Always"
                            />
                        </div>

                        <div className="form-group col-12">
                            <div className="e-float-input e-control-wrapper">
                                <TextBoxComponent
                                    value={data.mieteinnahmen}
                                    id="mieteinnahmen"
                                    name="mieteinnahmen"
                                    type="int"
                                    placeholder="Mieteinnahmen"
                                    floatLabelType="Always"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="form-group col-12">
                            <div className="e-float-input e-control-wrapper">
                                <TextBoxComponent
                                    value={data.baeh}
                                    id="baeh"
                                    name="baeh"
                                    type="int"
                                    placeholder="BAEH"
                                    floatLabelType="Always"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="form-group col-12 mb-3">
                            <div className="e-float-input e-control-wrapper">
                                <TextBoxComponent
                                    value={data.nveh}
                                    id="nveh"
                                    name="nveh"
                                    type="int"
                                    placeholder="NVEH"
                                    floatLabelType="Always"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Include the Help Modal */}
                {/*<HelpModal*/}
                {/*    isHelpModalOpen={isHelpModalOpen}*/}
                {/*    handleCloseHelpModal={this.handleCloseHelpModal}*/}
                {/*/>*/}
            </div>
        );
    }
}
