import * as React from 'react';
import { SidebarComponent, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Sidebar = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const sidebarobj = React.useRef(null);
    const treeviewobj = React.useRef(null);

    const [isReady, setIsReady] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
        toggleSidebar() {
            if (sidebarobj.current && treeviewobj.current) {
                if (sidebarobj.current.isOpen) {
                    sidebarobj.current.hide();
                    treeviewobj.current.collapseAll();
                } else {
                    sidebarobj.current.show();
                    treeviewobj.current.expandAll();
                }
            }
        }
    }));

    const data = [
        {nodeId: '01', nodeText: 'Home', iconCss: 'fa fa-home icon'},
        {nodeId: '02', nodeText: 'Benutzer', iconCss: 'fa fa-user icon'},
        {nodeId: '03', nodeText: 'Berater', iconCss: 'fa fa-user-tie icon'},
        {nodeId: '04', nodeText: 'Kunden', iconCss: 'fa fa-users icon'},
        {nodeId: '05', nodeText: 'Immobilien', iconCss: 'fa fa-building icon'},
        {nodeId: '06', nodeText: 'Notar', iconCss: 'fa fa-scale-balanced icon'},
    ];

    const width = '290px';
    const target = '.main-content';
    const mediaQuery = '(min-width: 600px)';
    const dockSize = "0px";
    const fields = {
        dataSource: data,
        id: 'nodeId',
        text: 'nodeText',
    };

    React.useEffect(() => {
        if (sidebarobj.current) {
            sidebarobj.current.element.style.visibility = '';
        }
        setIsReady(true); // Indicate that the component is ready to render
    }, []);

    const handleNodeSelected = (args) => {
        const {nodeData: {text: selectedNodeText}} = args;
        props.setTitle(selectedNodeText);

        const routes = {
            'Home': '/home',
            'Benutzer': '/benutzer',
            'Kunden': '/kunden',
            'Berater': '/berater',
            'Immobilien': '/immobilie',
            'Notar': '/notar',
        };

        navigate(routes[selectedNodeText] || '/');
    };

    return (
        <SidebarComponent
            id="sidebar-treeview"
            ref={sidebarobj}
            width={width}
            target={target}
            mediaQuery={mediaQuery}
            style={{visibility: "hidden"}}
            dockSize={dockSize}
            enableDock={true}
            className="custom-sidebar"
        >
            {isReady && (
                <div className='main-menu'>
                    <TreeViewComponent
                        id='main-treeview'
                        ref={treeviewobj}
                        fields={fields}
                        expandOn='Click'
                        nodeSelected={handleNodeSelected}
                    />
                </div>
            )}
        </SidebarComponent>
    );
});

export default Sidebar;
