//import React, { useEffect, useState } from 'react';
import './StartPage.css'; // Ensure to import the CSS file

const StartPage = () => {
    return (
        <div className="full-height">
            Startseite
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>dsfasaf
            <br/>
            <br/>
            <br/>
            <br/>sadfasdf
            <br/>
        </div>
        )
};

export default StartPage;