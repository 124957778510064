import React, { Component } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RichTextEditorComponent, Toolbar, Link,  Image, HtmlEditor, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
RichTextEditorComponent.Inject(Toolbar, Link, HtmlEditor, Image, QuickToolbar);

export class DialogFormTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(args) {
        const target = args.target || args.itemData;
        const name = target.name || args.name;
        const value = target.value || args.value;
        this.setState({ [name]: value });
    }

    handleRichTextChange(content) {
        this.setState({ bemerkung: content }); // Update the state with the content of the Rich Text Editor
    }

    render() {
        const data = this.state;
        const { anredeData } = this.props;
        const { statusData } = this.props;
        const { countryData } = this.props;
        return (
            <div className="e-gridform">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.kundennummer} id="kundennummer" name="kundennummer" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Kundennummer</label>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <DropDownListComponent
                            id="status_id"
                            name="status_id"
                            value={data.status_id}
                            dataSource={statusData}
                            fields={{text: 'status', value: 'id'}}
                            placeholder="Status"
                            popupHeight="300px"
                            floatLabelType="Always"
                            change={this.onChange}
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-2">
                        <DropDownListComponent
                            id="anrede_id"
                            name="anrede_id"
                            value={data.anrede_id}
                            dataSource={anredeData}
                            fields={{text: 'salutation', value: 'anrede_id'}}
                            placeholder="Anrede"
                            popupHeight="300px"
                            floatLabelType="Always"
                            change={this.onChange}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.vorname} id="vorname" name="vorname" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Vorname</label>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.nachname} id="nachname" name="nachname" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Nachname</label>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.anschrift} id="anschrift" name="anschrift" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Anschrift</label>
                        </div>
                    </div>

                    <div className="form-group col-md-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.plz} id="plz" name="plz" type="text" onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">PLZ</label>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.ort} id="ort" name="ort" type="text" onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Ort</label>
                        </div>
                    </div>



                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <DropDownListComponent
                            id="land_id"
                            name="land_id"
                            value={data.land_id}
                            dataSource={countryData}
                            fields={{text: 'country', value: 'id'}}
                            placeholder="Land"
                            popupHeight="300px"
                            floatLabelType="Always"
                            change={this.onChange}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <DropDownListComponent
                            id="staatsbuergerschaft_id"
                            name="staatsbuergerschaft_id"
                            value={data.staatsbuergerschaft_id}
                            dataSource={countryData}
                            fields={{text: 'staatsbuergerschaft', value: 'id'}}
                            placeholder="Staatsbürgerschaft"
                            popupHeight="300px"
                            floatLabelType="Always"
                            change={this.onChange}
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.telefon} id="telefon" name="telefon" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Telefon</label>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.email} id="email" name="email" type="text" onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Email</label>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="e-label-top mb-2">Bemerkung</label>
                        <RichTextEditorComponent
                            id="bemerkung"
                            value={data.bemerkung}
                            change={(e) => this.handleRichTextChange(e.value)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
