import React, { useEffect, useState } from 'react';
import {
    ForeignKey,
    Filter,
    ColumnChooser,
    Group,
    Sort,
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Inject,
    Edit,
    Reorder,
    Resize,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { DialogFormTemplate } from './BenutzerEditTemplate'; // Ensure correct path to the file
import './BenutzerStyles.css'; // Ensure to import the CSS file
import { anredeData, rightsData } from '../../data/luData.js';

const DataGridComponent = () => {
    const benutzerApiUrl = 'http://localhost:3001/api/benutzer';
    const [benutzer, setBenutzer] = useState([]);

    const FilterOptions = { type: 'Excel' };
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'ColumnChooser'];

    useEffect(() => {
        const benutzerDataManager = new DataManager({
            url: benutzerApiUrl,
            adaptor: new WebApiAdaptor()
        });

        const benutzerQuery = new Query();
        benutzerDataManager.executeQuery(benutzerQuery).then((e) => {
            setBenutzer(e.result);
            console.log('Fetched Benutzer Data:', e.result);
        }).catch((err) => {
            console.error('Error fetching Benutzer data:', err);
        });
    }, []);

    const handleActionComplete = (args) => {
        const commonHeaders = {
            'Content-Type': 'application/json'
        };
        let url = '';
        let options = {};

        if (args.requestType === 'save') {
            if (args.action === 'add') {
                url = benutzerApiUrl;
                options = {
                    method: 'POST',
                    headers: commonHeaders,
                    body: JSON.stringify(args.data)
                };
            } else if (args.action === 'edit') {
                url = `${benutzerApiUrl}/${args.data.id}`;
                options = {
                    method: 'PUT',
                    headers: commonHeaders,
                    body: JSON.stringify(args.data)
                };
            }

            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Record processed:', data);
                })
                .catch(error => {
                    console.error('Error processing record:', error);
                });
        } else if (args.requestType === 'delete') {
            url = `${benutzerApiUrl}/${args.data[0].id}`;
            options = {
                method: 'DELETE',
                headers: commonHeaders
            };

            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Record deleted:', data);
                })
                .catch(error => {
                    console.error('Error deleting record:', error);
                });
        }
    };
    return (
        <div className="full-height">
            <GridComponent
                autoFitColumns={'Auto'}
                height='calc(100vh - 240px)'
                width='100%'
                dataSource={benutzer}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowGrouping={true}
                allowReordering={true}
                allowResizing={true}
                resizeSettings={{ mode: 'Auto' }}
                autoFit={true}
                enablePersistence={true}
                showColumnChooser={true}
                toolbar={toolbarOptions}
                res
                filterSettings={FilterOptions}
                pageSettings={{ pageSize: 20 }}
                editSettings={{
                    allowAdding: true,
                    allowEditing: true,
                    allowDeleting: true,
                    mode: 'Dialog',
                    template: (props) => <DialogFormTemplate {...props} anredeData={anredeData} rightsData={rightsData} />
                }}
                actionComplete={handleActionComplete}
            >
                <ColumnsDirective>
                    <ColumnDirective field='id' headerText='ID' isPrimaryKey={true} type='int' width='100' textAlign='Right' visible={false} />
                    <ColumnDirective
                        field='anrede_id'
                        headerText='Anrede'
                        width='200'
                        foreignKeyValue='salutation'
                        foreignKeyField='anrede_id'
                        dataSource={anredeData}
                    />
                    <ColumnDirective field='benutzername' headerText='Benutzer' width='200' />
                    <ColumnDirective field='vorname' headerText='Vorname' width='200' />
                    <ColumnDirective field='nachname' headerText='Nachname' width='200' />
                    <ColumnDirective field='email' headerText='E-Mail' width='200' />

                    <ColumnDirective
                        field='rechte'
                        headerText='Rechte'
                        width='200'
                        foreignKeyValue='role'
                        foreignKeyField='id'
                        dataSource={rightsData}
                    />

                </ColumnsDirective>
                <Inject services={[Filter, Sort, Group, Page, Edit, Toolbar, Reorder, ColumnChooser, Resize, ForeignKey]} />
            </GridComponent>
        </div>
    );
};

export default DataGridComponent;