/* eslint-disable */
import React from 'react';
import '../../App.css';
//Report Viewer source
import '@boldreports/javascript-reporting-controls/Content/v2.0/tailwind-light/bold.report-viewer.min.css';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/common/bold.reports.common.min';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/common/bold.reports.widgets.min';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/bold.report-viewer.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';

var viewerStyle = {'height': '700px', 'width': '100%'};

function Report() {
    return (
        <div style={viewerStyle}>
            <BoldReportViewerComponent
                id="reportviewer-container"
                reportServiceUrl = {'https://demos.boldreports.com/services/api/ReportViewer'}
                reportPath = {'~/Resources/docs/sales-order-detail.rdl'} >
            </BoldReportViewerComponent>
        </div>
    );
}

export default Report;