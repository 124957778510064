import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';



export class DialogFormTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(args) {
        const target = args.target || args.itemData;
        const name = target.name || args.name;
        const value = target.value || args.value;
        this.setState({ [name]: value });
    }


    render() {
        const data = this.state;
        const { anredeData, rightsData } = this.props;

        return (
            <div className="e-gridform">

                <div className="form-row">

                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.benutzername} id="benutzername" name="benutzername" type="text"
                                   disabled={true} onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Benutzername</label>
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.password} id="password" name="password" type="password"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Passwort</label>
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <DropDownListComponent
                            id="rechte"
                            name="rechte"
                            value={data.rechte}
                            dataSource={rightsData}
                            fields={{text: 'role', value: 'id'}}
                            placeholder="Rechte"
                            popupHeight="300px"
                            floatLabelType="Always"
                            change={this.onChange}
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-4">
                        <DropDownListComponent
                            id="anrede_id"
                            name="anrede_id"
                            value={data.anrede_id}
                            dataSource={anredeData}
                            fields={{text: 'salutation', value: 'anrede_id'}}
                            placeholder="Anrede"
                            popupHeight="300px"
                            floatLabelType="Always"
                            change={this.onChange}
                        />
                    </div>
                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.vorname} id="vorname" name="vorname" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Vorname</label>
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.nachname} id="nachname" name="nachname" type="text"
                                   onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Nachname</label>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <div className="e-float-input e-control-wrapper">
                            <input value={data.email} id="email" name="email" type="text" onChange={this.onChange}/>
                            <span className="e-float-line"/>
                            <label className="e-float-text e-label-top">Email</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
